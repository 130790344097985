import React from 'react'
import { Helmet } from 'react-helmet'
import '../styles/index.css'

let bucketAddress = `https://guanyenc-1253565450.cos.ap-beijing.myqcloud.com/gugu`

const OpenDetailButton = (props) => {
  if (props.close === true) {
    return (
      <button className='openButton' onClick={() => props.setOpen()}>
        <img
          className='clickToRead'
          src={`${bucketAddress}/click-close.svg`}
          style={{ width: '50%' }}
          alt='click to read'
        />
      </button>
    )
  }
  return (
    <button className='openButton' onClick={() => props.setOpen()}>
      <img className='clickToRead' src={`${bucketAddress}/click-read.svg`} alt='click to read' />
      <img className='clickHand' src={`${bucketAddress}/pointer.svg`} alt='click to read' />
    </button>
  )
}

const ZineDetail = (props) => {
  let { vol, open } = props
  let showStyle = { display: open === vol ? 'flex' : 'none' }

  return (
    <div className='zineDetail' style={showStyle}>
      <img className='opeing' src={`${bucketAddress}/gugu_detail_${vol}.png`} alt='open' />
      <div className='close'>
        <OpenDetailButton setOpen={props.setOpen} close={true} />
      </div>
    </div>
  )
}

const GuguZine = () => {
  let [open, setOpen] = React.useState('')
  return (
    <main>
      <Helmet>
        <title>Guanyenc gugu-zine</title>
      </Helmet>
      <div className='fullBackground'>
        <div className='guguZinePage'>
          <div className='pageTitle'>GuGu Zine</div>
          <div className='titleBlock'>
            <div className='title'>
              <img src={`${bucketAddress}/title.png`} alt='title' />
            </div>
            <div className='msg'>
              《汩汩》是平时收集的免费出版物（说明书/宣传册/小票等）所组成的拼贴实体杂志
            </div>
          </div>
          <div className='zineBlock'>
            <img className='previewImage' src={`${bucketAddress}/cover1.png`} alt='vol1' />
            <div className='textBlock'>
              <img className='vol' src={`${bucketAddress}/vol1.svg`} alt='vol1' />
              <img className='title' src={`${bucketAddress}/vol-title-1.svg`} alt='vol1' />
              {open !== 1 && <OpenDetailButton setOpen={() => setOpen(1)} />}
            </div>
          </div>
          <ZineDetail vol={1} open={open} setOpen={() => setOpen('')} />
          <div className='zineBlock'>
            <img className='previewImage' src={`${bucketAddress}/cover2.png`} alt='vol2' />
            <div className='textBlock'>
              <img className='vol' src={`${bucketAddress}/vol2.svg`} alt='vol2' />
              <img className='title' src={`${bucketAddress}/vol-title-2.svg`} alt='vol2' />
              {open !== 2 && <OpenDetailButton setOpen={() => setOpen(2)} />}
            </div>
          </div>
          <ZineDetail vol={2} open={open} setOpen={() => setOpen('')} />

          <div className='zineBlock'>
            <img className='previewImage' src={`${bucketAddress}/cover3.png`} alt='vol3' />
            <div className='textBlock'>
              <img className='vol' src={`${bucketAddress}/vol3.svg`} alt='vol3' />
              <img className='title' src={`${bucketAddress}/vol-title-3.svg`} alt='vol3' />
              {open !== 3 && <OpenDetailButton setOpen={() => setOpen(3)} />}
            </div>
          </div>
          <ZineDetail vol={3} open={open} setOpen={() => setOpen('')} />
        </div>
      </div>
    </main>
  )
}

export default GuguZine
